<template>
  <a-spin :spinning="spinning" size="large">
    <search-form :items="items" @fnSearch="handleFnSearch" />
    <grid
      ref="grid"
      :columns="columns"
      :code="$route.path"
      :url="url"
      :search="search"
      :btn-operation="btnOperation"
      :scroll="{ x: 1500, y: gridHeight }"
    >
      <template #operation="{ record }">
        <operation :options="options" :record="record" />
      </template>
    </grid>
  </a-spin>
</template>

<script>
import { Spin } from 'ant-design-vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import tagApi from '@/api/tag.js';

export default {
  components: {
    ASpin: Spin,
    SearchForm,
    Grid,
    Operation,
  },
  data() {
    return {
      spinning: false,
      items: [
        { key: 'tagTypeCode', label: '编号' },
        { key: 'typeName', label: '名称' },
        { key: 'subType', label: '子类' },
      ],
      columns: [
        { dataIndex: 'tagTypeCode', title: '编号' },
        { dataIndex: 'typeName', title: '名称' },
        { dataIndex: 'type', title: '类别' },
        { dataIndex: 'typyDesc', title: '描述' },
        { dataIndex: 'subType', title: '子类' },
        {
          dataIndex: 'tagStatic',
          title: '静态标签',
          width: 80,
          customRender: tagStatic => (tagStatic ? '是' : '否'),
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          permission: 'tagmanage:add',
          fnClick: this.edit,
        },
        {
          type: 'exec',
          icon: 'ToolOutlined',
          permission: 'tagmanage:exec',
          label: '全部执行',
          fnClick: this.exec,
        },
      ],
      options: [
        {
          type: 'update',
          permission: 'tagmanage:update',
          fnClick: this.edit,
        },
        {
          type: 'exec',
          icon: 'ToolTwoTone',
          permission: 'tagmanage:exec',
          label: '执行',
          fnClick: this.exec,
        },
        {
          type: 'delete',
          permission: 'tagmanage:delete',
          fnClick: this.delete,
        },
      ],
      url: tagApi.pageUrl,
      gridHeight: document.body.clientHeight - 363,
      search: {},
    };
  },
  methods: {
    handleFnSearch(value) {
      this.search = value;
    },
    edit(data) {
      this.$router.push(`/datacenter/tagmanage/${data.id || 'add'}`);
    },
    delete({ id }) {
      tagApi.delete('tagmanage:delete', { ids: [id] }).then(() => {
        this.$message.success(this.$t('tips.deleteSuccess'));
        this.$refs.grid.refreshGrid();
      });
    },
    exec(data) {
      this.spinning = true;
      let temp = data
        ? tagApi.execTag('tagmanage:exec', data.id)
        : tagApi.execTags('tagmanage:exec');
      temp
        .then(() => {
          this.$message.success('执行成功！');
        })
        .finally(() => {
          this.spinning = false;
        });
    },
  },
};
</script>

<style scoped></style>
